


export default {
    middleware: 'guest',
    head() {
        return {
            title: `Connexion - ${this.$config.siteTitle}`,
            meta: [
                {hid: 'robots', name: 'robots', content: 'noindex'}
            ]
        }
    },
    data() {
        return {
            mode: 'login',
            path: ''
        };
    },
    methods: {
        logged() {
            const path = this.$auth.$storage.getUniversal('redirect');
            this.$auth.$storage.setUniversal('redirect', null);
            this.$router.push(path);
        },
        registerMode() {
            this.mode = 'register';
        },
        loginMode() {
            this.mode = 'login';
        },
    },
};
